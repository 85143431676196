<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 100 100"
    fill="none"
    class="spinner"
  >
    <circle
      cx="50"
      cy="50"
      r="35"
      stroke="currentColor"
      stroke-width="8"
      fill="none"
      stroke-dasharray="220"
      stroke-dashoffset="0"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        from="0 50 50"
        to="360 50 50"
        :dur="speed"
        repeatCount="indefinite"
      />
      <animate
        attributeName="stroke-dashoffset"
        from="0"
        to="440"
        :dur="speed"
        repeatCount="indefinite"
      />
    </circle>
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: [String, Number],
      default: 100
    },
    speed: {
      type: String,
      default: "1s" // Duration of the spin
    }
  }
};
</script>

<style scoped>
.spinner {
  color: #01663e; /* Set the spinner color */
}
</style>
